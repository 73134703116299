import Vue from 'vue';
import lodash from 'lodash';

import App from '@/js/App.vue';

import i18n from '@/js/plugins/i18n';
import router from '@/js/router';
import snackbar from '@/js/plugins/snackbar';
import store from '@/js/store';
import vuetify from '@/js/plugins/vuetify';

import '@/js/plugins/luxon';
import '@/js/plugins/restapi';

Vue.use(snackbar, { store });

Vue.prototype.$_ = window._ = lodash;

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');