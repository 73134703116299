export default {
    namespaced: true,

    state: {
        message: '',
        color: '',
        timeout: 0,
        icon: '',
    },

    mutations: {
        showMessage (state, payload) {
            state.message = payload.message;
            state.color = payload.color;
            state.timeout = payload.timeout;
            state.icon = payload.icon;
        },
    },
};
