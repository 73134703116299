import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/js/store';
import UserRole from '@/js/utilities/enums/UserRole';

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: () => import('@/js/layouts/Index'),
        children: [
            { path: '/', redirect: { name: 'Projects' } },
            {
                path: 'projects',
                name: 'Projects',
                component: () => import('@/js/views/Projects'),
                props: route => ({ projectId: _.toInteger(route.params.projectId) }),
                children: [
                    {
                        path: 'create',
                        name: 'ProjectCreate',
                        component: () => import('@/js/views/Projects/Create'),
                    },
                    {
                        path: ':projectId',
                        name: 'ProjectDetails',
                        component: () => import('@/js/views/Projects/Details'),
                        props: route => ({ projectId: _.toInteger(route.params.projectId) }),
                    },
                ],
            },
            {
                path: 'users',
                name: 'Users',
                component: () => import('@/js/views/Users'),
                props: route => ({ userId: _.toInteger(route.params.userId) }),
                meta: {
                    guard: UserRole.ADMINISTRATOR,
                },
                children: [
                    {
                        path: 'create',
                        name: 'UserCreate',
                        component: () => import('@/js/views/Users/Create'),
                    },
                    {
                        path: ':userId',
                        name: 'UserDetails',
                        component: () => import('@/js/views/Users/Details'),
                        props: route => ({ userId: _.toInteger(route.params.userId) }),
                    },
                ],
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('@/js/layouts/Auth'),
        meta: {
            guest: true,
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/js/views/auth/Login'),
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () => import('@/js/views/auth/ForgotPassword'),
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: () => import('@/js/views/auth/ResetPassword'),
                props: route => ({ email: _.toString(route.query.email), token: _.toString(route.query.token) }),
            },
        ],
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: () => import('@/js/views/PageNotFound'),
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

function checkRequiredUserRole (to) {
    const roleGuards = _.filter(to.matched, (record) => _.has(record.meta, 'guard'));

    if (_.isEmpty(roleGuards)) return true;

    const roles = _.map(roleGuards, (value) => {
        return value.meta.guard;
    });

    if (_.includes(roles, UserRole.ADMINISTRATOR)) {
        return router.app.$store.getters['auth/isAdministrator'];
    }

    return true;
}

router.beforeResolve(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (await store.dispatch('auth/isAuthenticated')) {
            next({ name: 'Projects' });
        } else {
            next();
        }
    } else {
        if (! await store.dispatch('auth/isAuthenticated')) {
            next({ name: 'Login', query: { redirect: to.path } });
        } else {
            if (! checkRequiredUserRole(to)) {
                if (from.name) {
                    router.app.$snackbar.showError(router.app.$t('messages.error.forbidden'));
                    next(false);
                } else {
                    next({ name: 'PageNotFound', replace: true });
                }
            }
            else {
                next();
            }
        }
    }
});

export default router;