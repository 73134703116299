const defaultTimeout = 5000;

const snackbarPlugin = {
    install: (Vue, { store }) => {
        if (! store) {
            throw new Error('Please provide vuex store.');
        }

        Vue.prototype.$snackbar = {
            /**
             * Show a message in the snackbar.
             *
             * @param {string} message Message to be shown.
             * @param {{color?:string, icon?:string, timeout?:number}} options Optional options for the snackbar
             */
            showMessage: function (message, options = {}) {
                store.commit(
                    'snackbar/showMessage',
                    {
                        message,
                        color: options?.color ?? 'primary',
                        icon: options?.icon,
                        timeout: options?.timeout ?? defaultTimeout,
                    },
                    { root: true },
                );
            },

            /**
             * Show a success message in the snackbar.
             *
             * @param {string} message Message to be shown.
             * @param {{timeout?:number}} options Optional options for the snackbar
             */
            showSuccess: function (message, options = {}) {
                store.commit(
                    'snackbar/showMessage',
                    {
                        message,
                        color: 'success',
                        icon: 'mdi-check',
                        timeout: options?.timeout ?? defaultTimeout,
                    },
                    { root: true },
                );
            },

            /**
             * Show an error message in the snackbar.
             *
             * @param {string} message Message to be shown.
             * @param {{timeout?:number}} options Optional options for the snackbar
             */
            showError: function (message, options = {}) {
                store.commit(
                    'snackbar/showMessage',
                    {
                        message,
                        color: 'error',
                        icon: 'mdi-alert-circle-outline',
                        timeout: options.timeout ?? defaultTimeout,
                    },
                    { root: true },
                );
            },
        };
    },
};

export default snackbarPlugin;
