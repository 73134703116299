import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/sass/overrides.scss';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {},
        themes: {},
    },
});
