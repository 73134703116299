import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/js/store/modules/auth';
import i18n from '@/js/store/modules/i18n';
import snackbar from '@/js/store/modules/snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
    getters: {
        isInitialized: state => {
            return state.auth.initialized && state.i18n.initialized;
        },
    },

    modules: {
        auth,
        i18n,
        snackbar,
    },
});
