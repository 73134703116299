import { Model } from '@packages/restapi';

export default class User extends Model {
    static attributes () {
        return {
            id: {
                type: Number,
            },
            name: {
                type: String,
            },
            email: {
                type: String,
            },
            roleSlug: {
                type: String,
            },
            statusSlug: {
                type: String,
            },
        };
    }

    static accessors () {
        return {
            isAdministrator: {
                type: Boolean,
            },
        };
    }

    static get endpoint () {
        return 'users';
    }
}
