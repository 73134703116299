import User from '@/js/models/User';

export default {
    namespaced: true,

    state: {
        initialized: false,
        hasCsrfCookie: false,

        user: null,
    },

    getters: {
        isAdministrator: state => {
            return state.user ? state.user.isAdministrator : false;
        },
    },

    actions: {
        async setCsrfCookie ({ commit, state }) {
            await api.request('csrf-cookie')
                .onSuccess((data) => {
                    commit('setHasCsrfCookie', true);
                })
                .onError((error) => {
                    commit('setHasCsrfCookie', false);
                })
                .get();
        },

        async loadAuthenticatedUser ({ commit, dispatch, state }) {
            if (! state.hasCsrfCookie) {
                dispatch('setCsrfCookie');
            }

            await api.request(`users/me?appends=[isAdministrator]`)
                .onSuccess((data) => {
                    commit('setUser', User.fromJson(data));
                })
                .onUnauthorized(() => {
                    commit('setUser', null);
                })
                .get();

            commit('setInitialized', true);
        },

        async isAuthenticated ({ dispatch, state }) {
            if (! state.initialized) {
                await dispatch('loadAuthenticatedUser');
            }

            return state.user !== null;
        },

        invalidate ({ commit }) {
            commit('setUser', null);
            commit('setInitialized', false);
        },
    },

    mutations: {
        setUser (state, user) {
            state.user = user;
        },

        setInitialized (state, initialized) {
            state.initialized = initialized;
        },

        setHasCsrfCookie (state, hasCsrfCookie) {
            state.hasCsrfCookie = hasCsrfCookie;
        },
    },
};
