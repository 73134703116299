<template>
    <div>
        <v-overlay :value="! isInitialized">
            <v-progress-circular
                indeterminate
                size="64"
            />
        </v-overlay>

        <router-view v-if="isInitialized" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters({
                isInitialized: 'isInitialized',
            }),
        },

        beforeCreate () {
            this.$store.dispatch('i18n/initialize');
        },
    };
</script>
