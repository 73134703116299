import i18n, { defaultLocale } from '@/js/plugins/i18n';

export default {
    namespaced: true,

    state: {
        initialized: false,

        locale: defaultLocale,
    },

    mutations: {
        updateLocale (state, newLocale) {
            state.locale = newLocale;
        },

        setInitialized (state, initialized) {
            state.initialized = initialized;
        },
    },

    actions: {
        initialize ({ commit, state }) {
            i18n.locale = state.locale;

            commit('setInitialized', true);
        },

        changeLocale ({ commit }, newLocale) {
            i18n.locale = newLocale;

            commit('updateLocale', newLocale);
        },
    },
};