import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages () {
    const locales = require.context('@/js/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getDefaultLanguage () {
    const languages = Object.getOwnPropertyNames(loadLocaleMessages());

    let defaultLanguage = null;

    languages.forEach(lang => {
        const languagePartial = navigator.language.split('-')[0];
        if (lang === languagePartial) {
            defaultLanguage = lang;
        }
    });

    return isLocaleAvailable(defaultLanguage)
        ? defaultLanguage
        : null;
}

function isLocaleAvailable (locale) {
    const locales = require.context('@/js/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

    return locales.keys()
        .map(key => key.match(/([A-Za-z0-9-_]+)\./i)[1])
        .some(key => key === locale);
}


export const defaultLocale = getDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'en';

export default new VueI18n({
    locale: defaultLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});
