import Enum from '@/js/utilities/enums/Enum';

export default class UserRole extends Enum {
    static ADMINISTRATOR = 'administrator';
    static USER = 'user';
}

const mixin = {
    methods: {
        userRoleTranslation (slug) {
            switch (slug) {
                case UserRole.ADMINISTRATOR:
                    return this.$t('enum.userRole.administrator');
                case UserRole.USER:
                    return this.$t('enum.userRole.user');
                default:
                    return slug;
            }
        },

        userRoleColor (slug) {
            switch (slug) {
                case UserRole.ADMINISTRATOR:
                    return 'primary';
                case UserRole.USER:
                    return 'secondary';
                default:
                    return null;
            }
        },
    },
};

export { mixin as userRoleMixin };
